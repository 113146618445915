import React, { useState } from "react";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Dialog } from "primereact/dialog";
import { CartPaymentModeDropdown } from "./cart-payment-dropdown";
import { TawreedFormField, TawreedInputTextarea, TawreedMenuDropdown } from "../../../../../common/components/form";

import { GlobalContext } from "../../../../../context";
import { paymentModeDto } from "../../domain";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../router";


export interface CheckoutFormProps {

    visible: boolean;
    /**
     *
     */
    onHide: (ok: boolean) => void;
    onAccept: (data: any) => void;
    priceListId: number;
    cartId: number;
}

interface CartCheckoutForm {
    paymentMode?: paymentModeDto;
    checkoutMode?: number;
    note?: string;
    payThroughUS?: boolean;

}

export const CartCheckoutDialog: React.FC<CheckoutFormProps> = (props) => {

    const [state, setState] = useState<CartCheckoutForm>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const { constants: { constants } } = React.useContext(GlobalContext);
    const history = useHistory();

    const DialogFooter = () => {
        console.log(state?.paymentMode?.isValid);
        console.log(!state?.paymentMode?.isValid);

        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_close')} icon={PrimeIcons.TIMES} onClick={() => onHide(false)} className="p-button-text" />
                <Button label={tawreedTranslationService.translate('lbl_okay')}
                    icon={PrimeIcons.CHECK}

                    disabled={!state || !state.paymentMode || getIsThereError() !== '' || 
                        !(state.checkoutMode !== undefined && state.checkoutMode >= 0)
                        || !state.paymentMode.isValid}
                    onClick={() => { props.onAccept(state) }}
                />
                <Button label={tawreedTranslationService.translate('lbl_onboard')}
                    icon={PrimeIcons.SAVE}
                    style={(!user || user.roleCode !== 'CUSTOMER_ADMIN' 
                        || !state?.paymentMode?.payThroughUS || !state.paymentMode.showOnBoard
                        || !state.paymentMode.paymentPartnerId) ? {display: "none"} :{}}
                    disabled={!state || !state.paymentMode || getIsThereError() !== '' || !(state.checkoutMode !== undefined && state.checkoutMode >= 0)}
                    onClick={() => { history.push(Routes.PaymentPartnerOnboardNew.replace(":id", state?.paymentMode?.paymentPartnerId ?
                        state?.paymentMode?.paymentPartnerId.toString() : "")) }}
                    className="p-button-danger"
                />
            </div>
        );
    }


    const onHide = (res: boolean) => {
        if (props.onHide) {
            props.onHide(res);
        }
    }
    const getIsThereError = () => {
        if (user && user.roleCode !== 'CUSTOMER_ADMIN' && state?.paymentMode?.payThroughUS && state?.checkoutMode === 0) {
            return <span style={{ color: 'red' }}>{tawreedTranslationService.translate('lbl_error_message_checkout_from')}</span>
        }
        return ''
    }
    return (
        <Dialog maximizable visible={props.visible} onHide={() => onHide(false)}
            header={tawreedTranslationService.translate('lbl_checkout_form')} footer={<DialogFooter />}
            position="top" breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} draggable={false} closable={true} focusOnShow closeOnEscape={true}>

            <div className="form-grid grid">
                <TawreedFormField name="paymentMode" title="lbl_payment_mode" className="field col-12">
                    <CartPaymentModeDropdown
                        optionValue="object"
                        render="standalone"
                        value={state?.paymentMode}
                        priceListId={props.priceListId}
                        cartId={props.cartId}
                        onPreSelected={(e) => {
                            setState({ ...state, paymentMode: e, checkoutMode: e.payThroughUS ? 1 : 0, payThroughUS: e.payThroughUS });
                        }}
                        onChange={(e) => {
                            console.log(e);
                            setState({ ...state, paymentMode: e.value, checkoutMode: e.value ? (e.value.payThroughUS ? 1 : 0) : undefined, payThroughUS: e.value ? e.value.payThroughUS : undefined });
                        }} />

                </TawreedFormField>


                {//state?.paymentMode && !state.paymentMode.payThroughUS &&
                    <React.Fragment>
                        <TawreedFormField name="checkoutMode" title="lbl_checkout_mode" className="field col-12">
                            <TawreedMenuDropdown
                                value={state?.checkoutMode}
                                onChange={(e) => {
                                    setState({ ...state, checkoutMode: e.value })
                                }}
                                menu={"checkOutModes"}
                                disabled={!state?.paymentMode}
                                name="store"
                                render="standalone"></TawreedMenuDropdown>

                        </TawreedFormField>
                        {getIsThereError()}
                    </React.Fragment>
                }
                {
                    state?.checkoutMode == 0 && 
                    <TawreedFormField name="Note" title="lbl_checkout_note" className="field col-12">
                        <TawreedInputTextarea
                            name="Note"
                            render="standalone"
                            value={state?.note}
                            onChange={(e) => {
                                setState({ ...state, note:e.target.value });
                            }} />

                    </TawreedFormField>
                }

            </div>
        </Dialog>
    );
}
