import React from "react";
import { tawreedTranslationService } from "../../../../common/translations";
import { Ripple } from "primereact/ripple";
import { DashboardCardItemDto, DashboardItemRoute, DashboardItemValue } from "../../domain";
import { Skeleton } from "primereact/skeleton";
import classNames from "classnames";
import { useHistory } from "react-router";


declare type DashboardCardProps = {
    /**
     * className
     */
    className?: string;

    /**
     * loading
     */
    loading: boolean;

    /**
     *
     */
    item: DashboardCardItemDto | undefined;

    valueCalculator: (value: DashboardItemValue) => string;
    routeCalculator: (value: DashboardItemRoute) => string;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {

    const history = useHistory();

    function goto() {
        if (props.item && props.item.to) {
            history.push(props.routeCalculator(props.item.to));
        }
    }
    const getImageOrIcon = (item: DashboardCardItemDto) => {
        if (item.image) {
            return <img src={item.image} alt='' />
        }
        else {
            return <i className={"text-blue-500 text-xl " + item.icon}></i>
        }

    }
    console.log("props.item",props.item);

    return (
        <div className={classNames('card mb-0 p-ripple', { 'cursor-pointer': props.item && props.item.to })} onClick={props.item && props.item.to && goto}>
            {
                props.item && props.item.to && <Ripple />
            }
            <div className="flex justify-content-between mb-1">
                <div className="w-full">
                    <span className="block font-medium mb-3">
                        {
                            (props.loading || !props.item) ? <Skeleton /> : tawreedTranslationService.translate(props.item.label)
                        }
                    </span>
                    <div className="text-900 font-medium text-xl">
                        {
                            (props.loading || !props.item) ? <Skeleton /> : props.valueCalculator(props.item.value)
                        }
                    </div>
                </div>
                <div className={`flex align-items-center justify-content-center border-round ${props.item?.image ? '' : 'bg-blue-100'}`} style={{ width: '2.5rem', height: '2.25rem' }}>
                    {
                        (props.loading || !props.item) ? <Skeleton shape="rectangle" height="100%" width="100%" /> : getImageOrIcon(props.item)
                    }
                </div>
            </div>
            <span className="text-green-500 font-medium">
                {
                    (props.loading || !props.item) ? <Skeleton /> : (props.item.subValue && props.valueCalculator(props.item.subValue))
                }
                &#8203;
            </span>
        </div>
    )
}
