import React, { useContext, useMemo, useState } from "react";
import { CartService, CustomerStoreCartDto, CustomerStoreCartItemDto } from "../../domain";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Message } from 'primereact/message';
import { TawreedTable, TawreedTableProps, TawreedTableColumnProps } from "../../../../../common/components/table";
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { CssUtils } from "../../../../../helpers/css";

import './cart-form-store-cart';
import { tawreedTranslationService } from "../../../../../common/translations";
import { Tag } from "primereact/tag";
import { ObjectUtils } from "../../../../../helpers/object";
import { GlobalContext } from "../../../../../context";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { CartSingleFormSummary } from "./cart-form-single-summary";
import { CartItemUpdateDialog } from "./cart-item-update-dialog";
import { Panel } from "primereact/panel";
import { StoreProductDto, StoreProductsService } from "../../../../catalog/store-products/domain";
import { AuthUtils } from "../../../../auth/domain";

export interface CartFormStoreCartProps {
    className: string;
    cart: CustomerStoreCartDto;
    onDelete: (cart: CustomerStoreCartDto) => Promise<void>;
    onDeleteItem: (item: CustomerStoreCartItemDto) => Promise<void>;
    onSave: (item: CustomerStoreCartItemDto) => Promise<boolean>;
    disabled: boolean;
    onCheckout: (cartId?: number) => void;
    initiatePayment:(data?: any) => boolean;
    priceListId: number;
    priceListName: string;
    salesOfferId?: string | null,
    onAdd: ()=>void,
    reload: boolean
}

export const CartFormStoreCart: React.FC<CartFormStoreCartProps> = (props) => {
    const { auth:{user}, constants: { constants } } = useContext(GlobalContext);
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);
    const lang = AuthUtils.current().language;
    const [updateItemDialog,setUpdateItemDialog] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<CustomerStoreCartItemDto>();
    const [selectedProduct, setSelectedProduct] = useState<StoreProductDto>();
    const cartService: CartService = React.useMemo(() => new CartService(), []);

    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return tawreedTranslationService.translate("lbl_unit");
        if (discount === 'percentage')
            return "%"

        return "%+"
    }
    
    let columns: TawreedTableColumnProps[] = [
        {
            dataType: 'text',
            field: "productName",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_product_name",
            body: (rowData: CustomerStoreCartItemDto) => {

                const productName = rowData.productName;
                const availableQuantity = rowData.availableQuantity ?? 0;

                return (
                    <div className="flex flex-column align-items-end md:align-items-start">
                        <span>{productName}</span>
                        {
                            availableQuantity < 0 && <Tag value={tawreedTranslationService.translate('lbl_sales_cart_store_cart_items_not_available')} severity="danger" rounded />
                        }
                    </div>
                )
            },
            style: CssUtils.width('20%'),
            bodyStyle: { 'unicode-bidi': 'plaintext' }
        },
        {
            dataType: 'text',
            field: "quantity",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_quantity",
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            style: CssUtils.width('5%'),
        },
        {
            dataType: 'numeric',
            field: "retailPrice",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_retail_price",
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.retailPrice, user?.scale!)
        },
        {
            dataType: 'numeric',
            field: "discountValue",
            header: "lbl_base_discount",
            sortable: true,
            filter: false,
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            className: CssUtils.className.field.amount,
            ...TawreedTableFormatters.currencyOrPercentageDepend('discountValue', 'discountType', constants?.info?.currencyCode ?? '', user?.scale!),
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_additional_discount",
            sortable: true,
            filter: false,
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => (options.bonusType === IBonusType.DISCOUNT ? ObjectUtils.toLocalString(options.bonus, user?.scale!) : 0) + " " + constants?.info?.currencyCode,
        },
        {
            dataType: 'numeric',
            field: "bonus",
            sortable:true,
            header: "lbl_store_products_bonus",
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => options.bonusType === IBonusType.EXTRA_ITEMS ? options.bonus : 0
        },
        {
            dataType: 'numeric',
            sortable: false,
            field: "appliedBonus",
            header: "lbl_store_products_applied_bonus",
            style: CssUtils.width('15%'),
            body: (options: any) => (
                options.appliedBonus && options.appliedBonus.length > 0 &&
                <React.Fragment>
                    <div className="bonuses">
                        {options.appliedBonus.map((e: any) => {
                            if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                            else
                                return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                        })
                        }
                    </div>
                </React.Fragment>
            )

        },
        {
            dataType: 'numeric',
            field: "itemGrossTotal",
            sortable:true,
            header: "lbl_sub_total1",
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount, user?.scale!)
        },
        {
            dataType: 'numeric',
            field: "storeProductTax",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_item_total_tax1",
            style: CssUtils.width('5%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            className: CssUtils.className.field.amount
        },
        {
            dataType: 'numeric',
            field: "itemTotal",
            header: "lbl_total1",
            sortable:true,
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            body: (options: any) => ObjectUtils.toLocalString(options.itemGrossTotal - options.itemTotalDiscount + options.itemTotalTax, user?.scale!)
        }];
    if (!props.salesOfferId && AuthUtils.current().authorized(TawreedActionName.CartsUpdate)) {
        columns = [...columns, {
            field: "delete",
            className: CssUtils.className.field.amount,
            body: (options: any) => {
                return (
                    <Button type="button" icon={PrimeIcons.PENCIL} onClick={() => 
                        {
                            service
                .getPrice(Number.parseInt(options.storeProductId),props.priceListId,props.cart.customerId!)
                .then((res) => {
                    setSelectedProduct(res);
                    setSelectedItem(options);
                    setUpdateItemDialog(true);
                });
                        }
                    } disabled={props.disabled} className="p-button-rounded p-button-text p-button-danger" />
                )
            }
        }];
    }
    const tawreed: TawreedTableProps = {
        reload: props.reload,
        pagination: true,
        dataKey: 'cartItemId',
        name: 'tbl.sales.carts.cart.store',
        ds: {
            mode: 'lazy',
            onSearch: (q) =>cartService.searchItems(props.cart.cartId!,props.cart.customerId!,q),
        },
        columns: [
            ...columns

        ],

        editable: {
            onEditComplete: (rowData, _field, _value) => props.onSave(rowData),
        },
        header: {
            bottom: props.cart.cartMessage ? <Message severity="warn" text={props.cart.cartMessage} className="w-full justify-content-start" /> : undefined,
        },
        selection: {
            mode: 'single',
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'productName',
                sortOrder: -1,
                multiSortMeta: undefined,
            }
        }
    }
    return (
        <Panel header={<React.Fragment>
            <span className="flex flex-row">
                <span className="mr-1">{props.cart.store}</span>
                <span>({ObjectUtils.toLocalString(props.cart.total, user?.scale!) + ' JOD'})({props.priceListName})</span>
            </span>
        </React.Fragment>} className="mt-2 col-12"

                icons={
                    AuthUtils.current().authorized(TawreedActionName.CartsStoreCartDelete) ?
                    <span className="p-panel-header-buttons">
                        {
                            [new TawreedAction(
                                TawreedActionName.CartsStoreCartDelete,
                                'statefull',
                                'lbl_delete',
                                PrimeIcons.TRASH,
                                () => props.onDelete(props.cart),
                                undefined,
                                'confirmDelete')].map(e => <Button type="button" icon={e.icon} onClick={() => e.execute()} disabled={props.disabled} className="p-button-text p-button-danger" label={tawreedTranslationService.translate(e.title.toString())} />)
                                
                        }
                        {!props.salesOfferId && <Button type="button" className="p-button-text p-button-success" icon={PrimeIcons.PLUS} onClick={() => props.onAdd()} label={tawreedTranslationService.translate("lbl_add")} disabled={props.disabled} />}
                    </span>
                    :
                    <span></span>
                }
            >
            <TawreedTable {...tawreed} />
            <CartSingleFormSummary priceListId={props.priceListId} className="col-12" 
                cart={props.cart} onCheckout={props.onCheckout} disabled={props.disabled || !props.cart.showPlaceOrder}
                cartId={props.cart.cartId}
                initiatePayment={props.initiatePayment} />
            <CartItemUpdateDialog
                customerId= {props.cart.customerId!}
                onHide={() => { setUpdateItemDialog(false) }}
                onSave={(data) => {
                   props.onSave(data)
                }}
                item={selectedItem!}
                onDeleteItem={(data) => {
                    props.onDeleteItem(data)
                 }}
                 storeProduct={selectedProduct!}
                visible={updateItemDialog} />
        </Panel>
    )
}
