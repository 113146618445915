import React from "react";
import { paymentModeDto } from "../../domain";
import { AuthUtils } from "../../../../auth/domain";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PrimeIcons } from "primereact/api";

export type PaymentItemTemplateProps = {
    /**
     *
     */
    rowData: paymentModeDto;

}

export const PaymentItemTemplate: React.FC<PaymentItemTemplateProps> = (props) => {

    return (
        <div className="">
            <div className="flex">
                <span className="mr-auto">
                    {AuthUtils.current().language === 'en' ? props.rowData.titleEn : props.rowData.titleAr}
                    </span>
                
            </div>
            <div style={{fontSize: '.75rem', color: 'var(--text-danger-color)'}}>
            <span className="ml-auto">
                    {props.rowData.msg}
                    </span>
            </div>
        </div>
    );
}
