import { JObject } from "../../../../common/data/models";
import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../common/pagination";
import { endpoints } from "../../../../constants/endpoints";
import { tawreedHttpService } from "../../../../http";
import { PaymentPartnerOnboard } from "../../domain";
import { PaymentPartnerOnboardMapper } from "../mappers/onboard";

export class PaymentPartnerOnboardRepository {

    // public search(request: PaginatedRequestParams): Promise<PaginatedResponse<PaymentPartnerOnboard>> {

    //     const params = {
    //         sort: request.sort ? buildSortParamsToString(request.sort) : undefined,
    //         page: request.page,
    //         size: request.size,
    //     };
    //     const data = { ...buildFilterMetaToObject(request.keyword, { ...request.filters }) };
    //     return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.PAYMENT_PARTNER_ONBOARD_SEARCH, data, params, undefined, 'error')
    //         .then(res => {
    //             return {
    //                 ...res!,
    //                 data: { ...res!.data!, content: res!.data!.content.map(e => PaymentPartnerOnboardMapper.fromJson(e)) ?? [] },
    //             };
    //         });
    // }

    // /**
    //  * Get partner details by id
    //  * @param id
    //  */
    // public async getDetails(id: number): Promise<PaymentPartnerOnboard> {
    //     return tawreedHttpService.post<number, JObject>(endpoints.PAYMENT_PARTNER_ONBOARD_GET_DETAILS, id, undefined, undefined, 'error')
    //     .then(res => PaymentPartnerOnboardMapper.fromJson(res.data!));
    // }

    public createOrUpdate(dto: PaymentPartnerOnboard): Promise<boolean> {
            return tawreedHttpService.post<JObject, JObject>(endpoints.ON_BOARD_SUBMIT, dto, undefined, undefined, 'error')
                .then(res => {
                    if(res && res.data){
                        return res.data.status
                    }
                    return false;
                });
        }
}