import React, { useState } from "react";
import { tawreedTranslationService } from "../../../../../common/translations";
import { Button } from "primereact/button";
import { CustomerStoreCartDto } from "../../domain";
import { ObjectUtils } from "../../../../../helpers/object";
import { CssUtils } from "../../../../../helpers/css";
import { CartCheckoutDialog } from "./cart-checkout-dialog"
import { GlobalContext } from "../../../../../context";
import { OtpDialog } from "./checkout-otp-popup";

export interface CartFormSummaryProps {
    className: string;
    cart: CustomerStoreCartDto;
    onCheckout: (data?: any) => void;
    initiatePayment: (data?: any) => boolean;
    disabled: boolean;
    priceListId: number;
    cartId?: number;
}
export interface CartCheckoutState {
    paymentModeId?: number,
    cartId?: number,
    checkoutMode?: number,
    note: string,
    otp: string
}
export const CartSingleFormSummary: React.FC<CartFormSummaryProps> = (props) => {


    const [checkoutPaymentDialog, setCheckoutPaymentDialog] = useState<boolean>(false);
    const [checkoutOtpDialog, setCheckoutOtpDialog] = useState<boolean>(false);
    const { auth:{user} } = React.useContext(GlobalContext);
    const [checkoutData, setCheckoutData] = useState<CartCheckoutState>({
        otp: "",
        note: ""
    });
    return (
        <React.Fragment>
            <div className="grid mt-3">
                <div className="col-12 md:col-6">
                    <div className="grid">
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_gross_total_1')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.grossTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_total_discounts')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.totalDiscount, user?.scale!)}
                        </div>


                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_sub_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.subTotal, user?.scale!)}
                        </div>
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_total_tax')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {ObjectUtils.toLocalString(props.cart.totalTax, user?.scale!)}
                        </div>
                        <hr style={{ width: '100%' }} />
                        <div className="col-6">
                            {tawreedTranslationService.translate('lbl_sales_cart_order_total')}
                        </div>
                        <div className="col-6" style={CssUtils.align('right')}>
                            {'JOD ' + ObjectUtils.toLocalString(props.cart.total, user?.scale!)}
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Button disabled={props.disabled} type="button" label={tawreedTranslationService.translate('lbl_checkout')} onClick={() => setCheckoutPaymentDialog(true)} className="inputfield w-full" />
                </div>
            </div>

            <CartCheckoutDialog
                priceListId={props.priceListId}
                cartId={props.cartId!}
                onHide={() => { setCheckoutPaymentDialog(!checkoutPaymentDialog) }}
                onAccept={(data) => {
                    setCheckoutPaymentDialog(false);
                    if(!data.payThroughUS || user?.roleCode !== 'CUSTOMER_ADMIN'){
                        props.onCheckout({
                            paymentModeId: data.paymentMode.paymentModeId,
                            cartId: props.cartId,
                            checkoutMode: data.checkoutMode,
                            note: data.note
                        });
                    }
                    else{
                        setCheckoutData({
                            paymentModeId: data.paymentMode.paymentModeId,
                            cartId: props.cartId,
                            checkoutMode: data.checkoutMode,
                            note: data.note,
                            otp:""
                        });
                        if(props.initiatePayment({
                            paymentModeId: data.paymentMode.paymentModeId,
                            cartId: props.cartId,
                            checkoutMode: data.checkoutMode,
                            note: data.note,
                            otp:""
                        }))
                        {
                            setCheckoutOtpDialog(true);
                        }
                    }
                }}
                visible={checkoutPaymentDialog} />
                <OtpDialog
                    visible={checkoutOtpDialog}
                    changeOtp={(value)=>{
                        setCheckoutData({
                            ...checkoutData,
                            otp: value
                        });
                    }}
                    accept={()=>{
                        
                        setCheckoutOtpDialog(false);
                        props.onCheckout({
                            paymentModeId: checkoutData.paymentModeId,
                            cartId: checkoutData.cartId,
                            checkoutMode: checkoutData.checkoutMode,
                            note: checkoutData.note,
                            otp: checkoutData.otp
                        });
                    }}
                    otpValue={checkoutData?.otp}
                    
                    hide={() => {setCheckoutOtpDialog(false)}}
                />
        </React.Fragment>

    );
}
