import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC } from 'react'
import { tawreedTranslationService } from '../../../../../../common/translations';
import OtpInput from 'react-otp-input';

export interface BonusDialogProps {
    visible: boolean;
    otpValue: string;

    hide: () => void;

    accept: () => void;
    changeOtp: (otpCode: string) => void;
}

export const OtpDialog: FC<BonusDialogProps> = ({ visible, hide, accept, changeOtp, otpValue }) => {

    const BonusDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
            <Button onClick={() => accept()} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={otpValue.length !== 4} />
        </div>);
    }

    return (

        <Dialog maximizable style={{ maxWidth: '512px', minWidth: '55%' }} header={<span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_otp_message')}</span>}
            visible={visible} footer={<BonusDialogFooter />} onHide={hide} draggable={false} position="top" closable={true} closeOnEscape={true}>


            <React.Fragment>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                        inputStyle={{ width: '100%', height: '50px' }}
                        numInputs={4}
                        isInputNum
                        separator={<span>-</span>}
                        value={otpValue}
                        onChange={(e: any) => {
                            changeOtp(e);
                        }}
                    />
                </div>


            </React.Fragment>

        </Dialog >
    )
}
