import React, { useEffect } from "react"
import { Panel } from "primereact/panel"
import { useHistory, useParams } from "react-router-dom"
import { GlobalContext } from "../../../../context";
import { PaymentPartnerOnboard, PaymentPartnerOnboardService } from "../../domain";
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile, TawreedInputNumber, TawreedInputText, TawreedSitesDropdown } from "../../../../common";
import { tawreedTranslationService } from "../../../../common/translations";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { PartnerType } from "../../../partners/data";
import { PartnersService } from "../../../partners/domain";
import { UsersService } from "../../../users/domain";
import { TawreedFile } from "../../../upload";
import { TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { toastService } from "../../../notifications/domain";


export const PaymentPartnerOnBoardForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: PaymentPartnerOnboardService = React.useMemo(() => new PaymentPartnerOnboardService(), []);
    const partnerService: PartnersService = React.useMemo(() => new PartnersService(), []);
    const userService: UsersService = React.useMemo(() => new UsersService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [commercialRecordForm, setCommercialRecordForm] = React.useState<File | undefined>();
    const [idImage, setIdImage] = React.useState<File | undefined>();

    const [data, setData] = React.useState<PaymentPartnerOnboard>({
        siteId: user?.siteId!,
        partnerId: user?.partnerId!,
        limit:0
    });

    const history = useHistory();
    const isMobileNoProvided = () => {
        return (data.phoneCode !== null && data.mobile != null && data.mobile.length > 0) ? true : 'msg_users_emailormobilenumberrequired';
    }

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            setData({ ...data, paymentPartnerId: Number.parseInt(id) })
            // service.getDetails(Number.parseInt(id))
            //     .then(res => {
            //         if (mounted) {
            //             setLoading(false);
            //             setData(res);
            //         }
            //     })
            //     .catch(() => {
            //         if (mounted) {
            //             setLoading(false);
            //         }
            //     })
            if (mounted) {
                setLoading(true);
                userService.getDetails(user?.userId!).then((res) => {
                    // setLoggedInUser(res);
                    console.log(res);
                    partnerService.getDetails(user?.partnerId!).then((customer) => {
                        setData({
                            ...data, phoneCode: res.phoneCode!, mobile: res.mobileNo!,
                            address: customer.address?.city ?? "",
                            establishmentNationalId: customer.establishmentNationalId!,
                            nationalId: customer.nationalId!, name: customer.partnerName!,
                        });

                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    });
                }).catch(() => {
                    setLoading(false);
                });

                setMode('Create');
                // setData({});
            }
        } else {

        }
        return () => {
            mounted = false;
        };
    }, [id, service]);

    useEffect(() => {
        let document = "";
        if (idImage) {
            let reader = new FileReader();
            reader.readAsDataURL(idImage);
            reader.onload = function () {
                document = reader.result as string;
                let documentParts = document.split(',');
                if (documentParts.length > 1) {
                    document = documentParts[1];
                }
                else {
                    document = documentParts[0];
                }
                setData(
                    {...data, idImage: document}
                );
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                setData(
                    {...data, idImage: ""}
                );
            };
        }
    }, [idImage]);

    useEffect(() => {
        let document = "";
        if (commercialRecordForm) {
            let reader = new FileReader();
            reader.readAsDataURL(commercialRecordForm);
            reader.onload = function () {
                document = reader.result as string;
                let documentParts = document.split(',');
                if (documentParts.length > 1) {
                    document = documentParts[1];
                }
                else {
                    document = documentParts[0];
                }
                setData(
                    {...data, commercialRecordForm: document}
                );
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                setData(
                    {...data, commercialRecordForm: ""}
                );
            };
        }
    }, [commercialRecordForm]);

    const onCreateOrSave = (data: PaymentPartnerOnboard, cb: TawreedFormSubmitCallback<PaymentPartnerOnboard>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (!commercialRecordForm || !idImage) {
                toastService.show({ detail: tawreedTranslationService.translate('msg_files_error'), severity: 'error' })
                reject();
                return;
            }
            setLoading(true);
            return service.createOrUpdate(data)
                .then(res => {
                    setLoading(false);
                    console.log(res);

                    if (res) {
                        history.goBack();
                    }
                })
                .catch(err => {
                    setLoading(false);
                    cb(err);
                });
        });

    }

    const onSubmit: TawreedFormSubmitProps<PaymentPartnerOnboard> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<PaymentPartnerOnboard>
            (TawreedActionName.OnBoard, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave, 'p-button-text mr-1'),
    };

    return (
        <TawreedForm title="lbl_payment_partners_onboard"
            dataKey="paymentPartnerOnboardId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            useBack=".." className="grid form-grid">
            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="nationalId" className="field col-12 md:col-6" title="lbl_payment_onboard_nationalId">
                        <TawreedInputText name="nationalId" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="establishmentNationalId" className="field col-12 md:col-6" title="lbl_payment_onboard_establishmentNationalId">
                        <TawreedInputText name="establishmentNationalId" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="name" className="field col-12 md:col-6" title="lbl_payment_onboard_name">
                        <TawreedInputText name="name" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="limit" className="field col-12 md:col-6" title="lbl_payment_onboard_limit">
                        <TawreedInputNumber min={0} name="limit" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="mobile" className="field col-12 md:col-6" title="lbl_payment_onboard_mobile">
                        <div className="p-inputgroup w-full">
                            <TawreedSitesDropdown name="phoneCode" rules={{ validate: isMobileNoProvided }}
                                onChange={e => setData({ ...data, phoneCode: e.target.value })} optionValue="value" render="form"
                                style={{ width: '33%' }} className="" />
                            <TawreedInputText
                                rules={{ validate: isMobileNoProvided }}
                                render="form"
                                name="mobile"
                                keyfilter="int"
                                maxLength={11}
                                onChange={e => setData({ ...data, mobileNo: e.target.value })}
                                className="inputfield" style={{ width: '66%' }} />
                        </div>
                    </TawreedFormField>

                    <TawreedFormField name="address" className="field col-12 md:col-6" title="lbl_payment_onboard_address">
                        <TawreedInputText name="address" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField title="lbl_payment_onboard_commercialRecordForm" className="field col-12 align" name="file">
                        <TawreedInputFile render='standalone' name="file" onChange={
                            (e: TawreedFile | undefined) => setCommercialRecordForm(e && e.value instanceof File ? e.value : undefined)
                        } value={commercialRecordForm} />
                    </TawreedFormField>
                    <TawreedFormField title="lbl_payment_onboard_idImage" className="field col-12 align" name="file">
                        <TawreedInputFile render='standalone' name="file" onChange={
                            (e: TawreedFile | undefined) => setIdImage(e && e.value instanceof File ? e.value : undefined)
                        } value={idImage} />
                    </TawreedFormField>
                </div>
            </Panel>
        </TawreedForm>
    )
}
