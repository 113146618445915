import { PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { PaymentPartnerOnboardRepository } from "../../data/repositories/onboard";
import { PaymentPartnerOnboard } from "../dtos";
export class PaymentPartnerOnboardService {

    private readonly repository;

    constructor() {
        this.repository = new PaymentPartnerOnboardRepository();
    }

    // public search(req: PaginatedRequestParams): Promise<PaginatedResponse<PaymentPartnerOnboard>> {
    //     return this.repository.search(req);
    // }

    // /**
    //  * Get partner details by id
    //  * @param id
    //  */
    // public getDetails(id: number): Promise<PaymentPartnerOnboard> {
    //     return this.repository.getDetails(id);
    // }

    public createOrUpdate(dto: PaymentPartnerOnboard): Promise<boolean> {
            return this.repository.createOrUpdate(dto);
        }
}