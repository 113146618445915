import { JObject } from "../../../../common/data/models";
import { OrdersCountsDto } from "../../domain";
import { PrimeIcons } from "primereact/api";
import { Routes } from "../../../../router";

import placedImg from "../../../../assets/dashoardicon/Placed.svg"

import proccingImg from "../../../../assets/dashoardicon/Processing.svg"

import readyImg from "../../../../assets/dashoardicon/Ready.svg"

import shippedImg from "../../../../assets/dashoardicon/Shipped.svg"



export const OrdersCountsMapper = {

    fromJson(json: JObject | undefined): OrdersCountsDto {
        return {
            placed: {
                name: 'placed',
                label: 'lbl_sales_stores_placed_orders',
                icon: PrimeIcons.BARS,
                image: placedImg,
                value: json?.placed,
                to: (_constants, user) => user.roleCode === 'CUSTOMER_ADMIN' ? Routes.SalesOrdersSearch : Routes.SalesOrdersPlacedSearch,
            },
            processing: {
                name: 'processing',
                label: 'lbl_sales_stores_processing_orders',
                icon: PrimeIcons.BARS,
                image: proccingImg,
                value: json?.processing,
                //dashboard routes
                to: (_constants, user) => user.roleCode === 'CUSTOMER_ADMIN' ? Routes.SalesOrdersSearch : Routes.SalesOrdersProcessingSearch,
            },
            shipped: {
                name: 'shipped',
                label: 'lbl_sales_customers_shipped_orders',
                icon: PrimeIcons.BARS,
                image: shippedImg,
                value: json?.shipped,
                to: (_constants, user) => user.roleCode === 'CUSTOMER_ADMIN' ? Routes.SalesOrdersSearch : Routes.SalesOrdersShippedSearch,
            },
            readyForDelivery: {
                name: 'readyForDelivery',
                label: 'lbl_sales_returned_orders_ready',
                icon: PrimeIcons.BARS,
                image: readyImg,
                value: json?.readyForDelivery,
                to: (_constants, user) => user.roleCode === 'CUSTOMER_ADMIN' ? Routes.SalesOrdersSearch : Routes.SalesOrdersReadySearch,
            },
        };
    },
}
